import { useState } from 'react';
import '../../StyleFiles/signIn.css'
import GetHeader from '../../GetSections/GetHeader';
import ResetPasswordFromSignIn from '../ResetPasswordFromSignIn/resetPasswordFromSignIn';
import MC_logo from '../../images/MC_logo.png'

const SignIn = ( { onRouteChange, isSignedIn, setUserProfileOnSignIn } ) => {
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ displayedError, setDisplayedError ] = useState('')

  // *************************************************
  // *** FUNCTIONS TO SET STATE WHEN INPUT UPDATED ***
  // *************************************************

  // Function to update state when the email input is updated
  const onEmailChange = (event) => {
    setEmail(event.target.value)
    setDisplayedError('')
  }

  // Function to update state when the password input is updated
  const onPasswordChange = (event) => {
    setPassword(event.target.value)
    setDisplayedError('')
  }
 
  // Function to reset password from sign in page
  const onSubmitResetPasswordFromSignIn = () => {
    onRouteChange('resetPasswordFromSignIn')
  }
  
  // ******************************************************
  // *** FUNCTION TO SEND DATA TO SERVER WHEN SIGNIN IN ***
  // ******************************************************

  // // Use ternary to set the url of the API when in deployment or developmemt 
  // const baseURL = process.env.NODE_ENV === "production" ? "/signin" : 'http://localhost:3001/signin'

  // Function sends email and password request when signing in. 
  const onSubmitSignIn = () => {
    fetch('api/v1/signin', {
      method: 'post',
      headers: {'Content-Type' : 'application/json'},
      body: JSON.stringify({
        email: email, 
        password: password})
    })
    //  Redirection/route on received response;
    .then(res => res.json())
    .then(response => {
      if (typeof(response) === 'object') {
        onRouteChange('home')
        setUserProfileOnSignIn( response )
      } else if 
      ( typeof(response) === 'string' ) {
        setDisplayedError(response)
        onRouteChange('signin')
      }
    })
  };

  // ****************************
  // *** RETURNS SIGN IN FORM ***
  // ****************************

  return(
    <>
      <GetHeader isSignedIn = { isSignedIn } onRouteChange = { onRouteChange } route = { 'signin' } /> 
      <p></p>
      <article className="br2 ba dark-gray b--black-10 mv4 w-50-m w-25-l mw5 center">
        <main className="pa4 black-80 background-colour-box">
        <form className="measure center">
          <img className="logo-image" src={MC_logo} alt="oops" />
          <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
            <div className="mt3">
              <label 
              className="db fw6 lh-copy f6" 
              htmlFor="email-address">Email</label>
              <input 
                onChange = { onEmailChange } 
                className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
                type="email" 
                name="email-address"  
                id="email-address" />
            </div>
            <div className="mv3">
              <label 
              className="db fw6 lh-copy f6" 
              htmlFor="password">Password</label>
              <input 
                onChange = { onPasswordChange } 
                className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
                type="password" 
                name="password"  
                id="password" />
            </div>
          </fieldset>
          <div className="">
            {/* Add event handler on click, so when clicked, the route is changed */}
            {/* Adding it as an arrow function, so it does not get called when rendered, but
            // only when clicked on. */}
            <input 
              onClick = { onSubmitSignIn }
              className="b pv2 ba  grow pointer f6 dib" 
              type="button" 
              value="Sign in" />
          </div>
          <div className="fw6 f6 pv2 b displayed-error-text-colour">
            <button className="b pv2 ba  grow pointer f6 dib"
            onClick = { onSubmitResetPasswordFromSignIn }
            type="button" 
            >Forgot your password?</button>
          </div>
          <a 
            className = "fw6 f6 displayed-error-text-colour" 
              >{ displayedError }</a>
        </form>
      </main>
      </article>
    </>
  );
}

export default SignIn;