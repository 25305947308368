import React, { useEffect, useRef } from "react"
import '../StyleFiles/StatisticsTable.css';

const GetStatisticsTable = ( props ) => {
  const updatedValue = props.data[0]

  var mortgageSum = (parseFloat(updatedValue.new_mortgage -
    parseFloat((updatedValue.px - updatedValue.old_mortgage)) +
    parseFloat(updatedValue.stamp_duty) +
    parseFloat(updatedValue.early_repayment) +
    parseFloat(updatedValue.others) -
    parseFloat(updatedValue.deposit)))

  var promotionalApr = parseFloat(updatedValue.promotional_apr).toFixed(2)
  var followOnApr = parseFloat(updatedValue.follow_on_apr).toFixed(2)

  var numberOfPayments = function() {
    // Return term times: full, promotional, follow-on
    var numOfPaymentsFullTerm = parseFloat(updatedValue.loan_term) * 12
    var numOfPaymentsPromotionalTerm = parseFloat(updatedValue.promotional_apr_term) * 12
    var numOfPaymentsFolowOnTerm = numOfPaymentsFullTerm - numOfPaymentsPromotionalTerm
    return [numOfPaymentsFullTerm, numOfPaymentsPromotionalTerm, numOfPaymentsFolowOnTerm]
  }

  var totalInstalmentsAndMonthlyInstalments = function(){
    // Convert the APR percentage into a float, then divide this by the number of months/year
    var promotionalAprConverted = (parseFloat(updatedValue.promotional_apr) / 100) / 12
    var followOnAprConverted = (parseFloat(updatedValue.follow_on_apr) / 100) / 12
    var remainingPrincipalAfterPromotionalPeriod = parseFloat(mortgageSum - (numberOfPayments()[1] * (mortgageSum  / numberOfPayments()[0])))
    
    // Update Instalments section values
    var monthlyInstalmentsPromotionalPeriod = ((mortgageSum*((promotionalAprConverted * (1 + promotionalAprConverted)** parseFloat(numberOfPayments()[0])) / ((1+promotionalAprConverted)**parseFloat(numberOfPayments()[0])-1))))
    var monthlyInstalmentsFollowOnPeriod = (remainingPrincipalAfterPromotionalPeriod * ((followOnAprConverted * (1 + followOnAprConverted)**parseFloat(numberOfPayments()[2])) /
    ((1+followOnAprConverted)**parseFloat(numberOfPayments()[2])-1))).toFixed(2)
    // var instalmentsTotal = (monthlyInstalments * numberOfPayments()[0])
    return [ monthlyInstalmentsPromotionalPeriod, promotionalAprConverted, followOnAprConverted, remainingPrincipalAfterPromotionalPeriod, monthlyInstalmentsFollowOnPeriod ]
}

  return (
    <>
      <div className="statistics-wrapper">
        <a className="statistics-header-wrapper"><strong>STATISTICS</strong></a>
        <table className="table-striped">
          <thead>
            <tr>
              <th scope="col" className='column-header title'>Title</th>
              <th scope="col" className='column-header value-push'>Value</th>
            </tr>
          </thead>
        <tbody>
          <tr>
            <td>New Mortgage Sum Required (GBP)</td>
            <td id="mortgage_amount_statistics_value" className='value-push'>{ JSON.stringify((mortgageSum)) }</td>
          </tr>
          <tr>
            <td>Loan Term (Months)</td>
            <td id="loan_term_statistics_value" className='value-push'>{ JSON.stringify(numberOfPayments()[0]) }</td>
          </tr>
          <tr>
            <td>Promotional (P) APR (%)</td>
            <td id="pAPR_statistics_value" className='value-push'>{ JSON.stringify(parseFloat(promotionalApr)) }</td>
          </tr>
          <tr>
            <td>Promotional (P) APR / Month (%)</td>
            <td id="monthly_pAPR_statistics_value" className='value-push'>{ JSON.stringify(parseFloat(parseFloat(totalInstalmentsAndMonthlyInstalments()[1] * 100).toFixed(4))) }</td>
          </tr>
          <tr>
            <td>Monthly Instalments P Period (Principal + APR, GBP)</td>
            <td id="instalments_promotional_statistics_value" className='value-push'>{ JSON.stringify(parseFloat(parseFloat(totalInstalmentsAndMonthlyInstalments()[0]).toFixed(2))) }</td>
          </tr>
          <tr>
            <td>Remaining Principal After P Period (GBP)</td>
            <td id="remaining_principal_after_promotion_statistics_value" className='value-push'>{ JSON.stringify(parseFloat(parseFloat(totalInstalmentsAndMonthlyInstalments()[3]).toFixed(2))) }</td>
          </tr>
          <tr>
            <td>Follow-on (FO) APR (%)</td>
            <td id="foAPR_statistics_value" className='value-push'>{ JSON.stringify(parseFloat(followOnApr)) }</td>
          </tr>
          <tr>
            <td>Follow-on (FO) APR / Month (%)</td>
            <td id="monthly_foAPR_statistics_value" className='value-push'>{ JSON.stringify(parseFloat(parseFloat(totalInstalmentsAndMonthlyInstalments()[2] * 100).toFixed(4))) }</td>
          </tr>
          <tr>
            <td>Monthly Instalments FO Period (Principal + APR, GBP)</td>
            <td id="instalments_fo_statistics_value" className='value-push'>{ JSON.stringify(parseFloat(parseFloat(totalInstalmentsAndMonthlyInstalments()[4]).toFixed(2))) }</td>
          </tr>
        </tbody>
      </table>
      </div>
    </>
  );
}

export default GetStatisticsTable;
