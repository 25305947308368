import { Outlet } from 'react-router-dom'

function Layout( { route }){
    // Renders a basic page ('/'), and we can redner anything on top of this (e.g. '/signin')
    // TODO: render the header here, so this will be the basic page.
    return(
        <div className = 'main-div'>
            <div className='header-sections-wrapper'>
                {/* This connects the form to the routes */}
                <Outlet />
            </div>
        </div>
    )
}

export default Layout