import React from "react";

const InstalmentTextBoxComponent = ( props ) => {
    const textboxItem =
        <> 
            <input 
                type = { props.textbox.className } 
                id = { props.textbox.id } 
                className = { props.textbox.className } 
                value = { props.value }
                readOnly = { true }
                onChange = { props.onChange }
                >
            </input>
        </>

    return (
        <>
            {textboxItem}
        </>
    )
}

export default InstalmentTextBoxComponent