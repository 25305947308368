export const instalmentComponentsList = [
    {   
        wrapper: {
            key: 37,
            className: 'mortgage_needed input'
        },
        textbox : {
            key: 38,
            type:"number",
            id: "mortgage_needed_value",
            className: "valuebox-instalments", 
            defaultValue: '0'
        },
        label : {
            key: 39,
            htmlFor:"mortgage_needed_value",
            className: "form-label", 
            text: 'New Mortgage Sum Required (GBP)'
        }
    },
    {
        wrapper: {
            key: 40,
            className: 'total_payment input'
        },
        textbox : {
            key: 41,
            type:"number",
            id: "total_payment_value",
            className: "valuebox-instalments", 
            defaultValue: '0'
        },
        label : {
            key: 42,
            htmlFor:"total_payment_value",
            className: "form-label", 
            text: 'Total Payed Amount (GBP)'
        }
    },
    {
        wrapper: {
            key: 43,
            className: 'instalment_p_principal_apr input'
        },
        textbox : {
            key: 44,
            type: "number",
            id: "instalment_p_principal_apr_value",
            className: "valuebox-instalments", 
            defaultValue: '0'
        },
        label : {key: 45,
            htmlFor:"instalment_p_principal_apr_value",
            className: "form-label", 
            text: 'Initial Monthly Instalments (Principal + APR, GBP)'
        }
    },
]