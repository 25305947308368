
export const inputComponentsList = [
    {
        label: {
            key: 1,
            htmlFor: "new_mortgage_slider",
            className: "form-label",
            text: 'Price of New Property (GBP)'
        },
        textbox: {
            key: 2,
            type: "number",
            id: "new_mortgage_value",
            className: "valuebox-input",
            defaultValue: '0',
            step: "1000"
        },
        slider: {
            key: 3,
            type: "range",
            className: "form-range",
            min: "0",
            max: "1000000",
            step: "1000",
            defaultValue: '0',
            id: "new_mortgage_slider"
        }
    },
    {
        label: {
            key: 4,
            htmlFor: "old_mortgage_slider",
            className: "form-label",
            text: 'Remaining Mortgage on Old Property (GBP)'
        },
        textbox: {
            key: 5,
            type: "number",
            id: "old_mortgage_value",
            className: "valuebox-input",
            defaultValue: '0'
        },
        slider: {
            key: 6,
            type: "range",
            className: "form-range",
            min: "0",
            max: "1000000",
            step: "1000",
            defaultValue: '0',
            id: "old_mortgage_slider"
        }
    },
    {
        label: {
            key: 7,
            htmlFor: "px_slider",
            className: "form-label",
            text: 'P/X: Selling Price - Remaining Mortgage Balance on Old Property (GBP)'
        },
        textbox: {
            key: 8,
            type: "number",
            id: "px_value",
            className: "valuebox-input",
            defaultValue: '0'
        },
        slider: {
            key: 9,
            type: "range",
            className: "form-range",
            min: "0",
            max: "1000000",
            step: "1000",
            defaultValue: '0',
            id: "px_slider"
        }
    },
    { 
        label: {
            key: 10,
            htmlFor: "early_repayment_slider",
            className: "form-label",
            text: 'Early Repayment Fees (GBP)'
        },
        textbox: {
            key: 11,
            type: "number",
            id: "early_repayment_value",
            className: "valuebox-input",
            defaultValue: '0'
        },
        slider: {
            key: 12,
            type: "range",
            className: "form-range",
            min: "0",
            max: "50000",
            step: "10",
            defaultValue: '0',
            id: "early_repayment_slider"
        }
    },  
    { 
        label: {
            key: 13,
            htmlFor: "stamp_duty_slider",
            className: "form-label",
            text: 'Stamp Duty (GBP)'
        },
        textbox: {
            key: 14,
            type: "number",
            id: "stamp_duty_value",
            className: "valuebox-input",
            defaultValue: '0'
        },
        slider: {
            key: 15,
            type: "range",
            className: "form-range",
            min: "0",
            max: "20000",
            step: "10",
            defaultValue: '0',
            id: "stamp_duty_slider"
        }
    },
    {
        label: {
            key: 16,
            htmlFor: "others_slider",
            className: "form-label",
            text: 'Others Expenses (GBP)'
        },
        textbox: {
            key: 17,
            type: "number",
            id: "others_value",
            className: "valuebox-input",
            defaultValue: '0'
        },
        slider: {
            key: 18,
            type: "range",
            className: "form-range",
            min: "0",
            max: "150000",
            step: "100",
            defaultValue: '0',
            id: "others_slider"
        }
    },
    {
        label: {
            key: 19,
            htmlFor: "deposit_slider",
            className: "form-label",
            text: 'Deposit (GBP)'
        },
        textbox: {
            key: 20,
            type: "number",
            id: "deposit_value",
            className: "valuebox-input",
            defaultValue: '0'
        },
        slider: {
            key: 21,
            type: "range",
            className: "form-range",
            min: "0",
            max: "100000",
            step: "100",
            defaultValue: '0',
            id: "deposit_slider"
        }
    },
    {
        label: {
            key: 25,
            htmlFor: "promotional_apr_slider",
            className: "form-label",
            text: 'Promotional APR (%)'
        },
        textbox: {
            key: 26,
            type: "number",
            id: "promotional_apr_value",
            className: "valuebox-input",
            defaultValue: '4'
        },
        slider: {
            key: 27,
            type: "range",
            className: "form-range",
            min: "0",
            max: "15",
            step: "0.1",
            defaultValue: '4',
            id: "promotional_apr_slider"
        }
    },
    {
        label: {
            key: 28,
            htmlFor: "promotional_apr_term_slider",
            className: "form-label",
            text: 'Promotional APR Length (Years)'
        },
        textbox: {
            key: 29,
            type: "number",
            id: "promotional_apr_term_value",
            className: "valuebox-input",
            defaultValue: '5'
        },
        slider: {
            key: 30,
            type: "range",
            className: "form-range",
            min: "0",
            max: "30",
            step: "1",
            defaultValue: '5',
            id: "promotional_apr_term_slider"
        }
    },
    {
        label: {
            key: 31,
            htmlFor: "follow_on_apr_slider",
            className: "form-label",
            text: 'Follow-on APR (%)'
        },
        textbox: {key: 32,
            type: "number",
            id: "follow_on_apr_value",
            className: "valuebox-input",
            defaultValue: '7'
        },
        slider: {
            key: 33,
            type: "range",
            className: "form-range",
            min: "1",
            max: "15",
            step: "0.1",
            defaultValue: '7',
            id: "follow_on_apr_slider"
        }
    },
    {
        label: {
            key: 34,
            htmlFor: "loan_term_slider",
            className: "form-label",
            text: 'Loan Term (Years)'
        },
        textbox: {
            key: 35,
            type: "number",
            id: "loan_term_value",
            className: "valuebox-input",
            defaultValue: '25'
        },
        slider: {
            key: 36,
            type: "range",
            className: "form-range",
            min: "0",
            max: "35",
            step: "1",
            defaultValue: '25',
            id: "loan_term_slider"
        }
    },
    
]
