import React, { useState } from 'react'
import LabelComponent from "../BasicComponents/LabelComponent";
import InputTextBoxComponent from "../BasicComponents/InputTextBoxComponent";
import SliderComponent from "../BasicComponents/SliderComponent";

import { inputComponentsList } from '../Data/inputComponentsList';
import GetInstalmentsSection from './GetInstalmentsSection';
import GetStatisticsTable from './GetStatisticsTable';

import '../StyleFiles/getSections.css'
import GetCharts from './GetCharts';

// Function to gather components to build the Mortgage Input Section.
const GetSections = ( { signedInUserProfile } ) => {
    // User data with email address and default textbox values (values lifted from signin form/BE to App, then passed down)
    const [ data, setData ] = useState({signedInUserProfile})
    const [ displayedError, setDisplayedError ] = useState('')

    // Method to set useState onChange - creates a copy of the object, then replacec the changed value
    const setValueOnChange = ( v ) => {
        let val = v.target.value;
        //  removes the '_slider' and '_value' from the end of the IDs.
        let ident = (v.target.id).replace(/_slider|_value/gi, '')
        let newDat = data.signedInUserProfile.data.slice()
        newDat[0][ident] = val
        setData({"signedInUserProfile":{email:data.signedInUserProfile.email, data: newDat}})
        // Removes notification message if user data is saved
        setDisplayedError('')
      };

    //   Method to send data to BE when saved
    // Function to send registration information to server
  const onSave = () => {
    fetch('api/v1/savedata', {
      method: 'post',
      headers: {'Content-Type' : 'application/json'},
      body: JSON.stringify({
        email: data.signedInUserProfile.email,
        data: data.signedInUserProfile.data
      })
    })
    //  Redirection/route on received response;
    .then(res => res.json())
    .then(response => {
      if (response.includes('successfully updated.') ) {
        setDisplayedError(response)
      } else {
        setDisplayedError(response)
      }
    })
  };

    //  Function to reset form onResetForm button click
    const onResetForm = () => {
        setData( { "signedInUserProfile" : { "email" : data.signedInUserProfile.email,
            "data": [{
                "new_mortgage":  "0", "old_mortgage": "0", "px":"0", "early_repayment":"0", 
                "stamp_duty":"0", "others":"0", "deposit":"0", "monthly_extra_payment":"0", 
                "promotional_apr":"4", "promotional_apr_term":"5", "follow_on_apr":"7", 
                "loan_term":"25"
            }]
        }})
        setDisplayedError('Form reset')
    }
    
    const getInputSection = inputComponentsList.map((input, i) => {
        return (
            <>
            {/* Add key=ia or ib, etc. so React handles all elements in the loop as unique. Using formatted string. */}
            <div className = "input-section" >
                <div className = "label-wrapper" >
                    <LabelComponent
                        label = { inputComponentsList[i].label }
                     />
                </div>
                <div className="textbox-slider-wrapper" >
                    <div className = "textbox-wrapper" >
                        <InputTextBoxComponent 
                            textbox = { inputComponentsList[i].textbox }
                            onChange = { setValueOnChange }
                            value = { data.signedInUserProfile.data[0][inputComponentsList[i].slider.id.replace(/_slider|_value/gi, '')] }
                         />
                    </div>
                    <div className = "slider-wrapper" >
                        <SliderComponent
                            slider= { inputComponentsList[i].slider }
                            onChange = { setValueOnChange }
                            value = { data.signedInUserProfile.data[0][inputComponentsList[i].textbox.id.replace(/_slider|_value/gi, '')] }
                         />
                    </div>
                </div>
            </div>
            </>
        )}
    )

    return (
        <>
            <div className = "input-section-top-level">
                <div className="input-header-button-wrapper" >
                    <a className="input-header-wrapper"><strong>INPUT</strong></a>
                    <div className='input-buttons-error-wrapper'>
                        <button className="header-wrapper about-account-login-register-progress-help-buttons btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                            Progress
                        </button>
                        <ul className="dropdown-menu">
                            <div className='input-buttons-wrapper'>
                                <button onClick = { onResetForm} className="header-wrapper account-home button dropdown-item" type="button" >Reset form</button>
                                <button onClick = { onSave} className="header-wrapper account-home button dropdown-item" type="button" >Save</button>
                            </div>
                        </ul>
                        <a>{ displayedError }</a>
                    </div>
                </div>
                {  getInputSection }
            </div>
            <div className = "instalments-statistics-graphs-wrapper">
                <div className = "instalments-statistics-wrapper">
                    <div className = "instalments-section">
                        {<GetInstalmentsSection data = { data.signedInUserProfile.data } />}
                    </div>
                    <div className = "statistics-section">
                        {<GetStatisticsTable data = { data.signedInUserProfile.data } />}
                    </div>
                </div>
                <div className = "graphs-wrapper">
                        {<GetCharts data = { data.signedInUserProfile.data } />}
                </div>
            </div>
        </>
    )
}

export default GetSections