import LabelComponent from "../BasicComponents/LabelComponent";
import InstalmentTextBoxComponent from "../BasicComponents/InstalmentTextboxComponent";
import { instalmentComponentsList } from "../Data/instalmentComponentsList"
import { useEffect, useRef, useState } from "react";

import '../StyleFiles/getInstalmentsSection.css'

const GetInstalmentsSection = ( props ) => {
    const updatedValue = props.data[0]    

    // Get mortgage sum needed
    var mortgageSum = parseFloat(updatedValue.new_mortgage -
                      parseFloat((updatedValue.px - updatedValue.old_mortgage)) +
                      parseFloat(updatedValue.stamp_duty) +
                      parseFloat(updatedValue.early_repayment) +
                      parseFloat(updatedValue.others) -
                      parseFloat(updatedValue.deposit))

    // Calculates loan term values
    var numberOfPayments = function() {
        // Return term times: full, promotional, follow-on
        var numOfPaymentsFullTerm = parseFloat(updatedValue.loan_term) * 12
        var numOfPaymentsPromotionalTerm = parseFloat(updatedValue.promotional_apr_term) * 12
        var numOfPaymentsFolowOnTerm = numOfPaymentsFullTerm - numOfPaymentsPromotionalTerm
        return [numOfPaymentsFullTerm, numOfPaymentsPromotionalTerm, numOfPaymentsFolowOnTerm]
    }

    var totalInstalmentsAndMonthlyInstalments = function(){
        // Convert the APR percentage into a float, then divide this by the number of months/year
        var promotionalAprConverted = (parseFloat(updatedValue.promotional_apr) / 100) / 12
        // Update Instalments section values
        var monthlyInstalments = ((mortgageSum*((promotionalAprConverted * (1 + promotionalAprConverted)** parseFloat(numberOfPayments()[0])) / ((1+promotionalAprConverted)**parseFloat(numberOfPayments()[0])-1))))
        var instalmentsTotal = (monthlyInstalments * numberOfPayments()[0])
        return [ monthlyInstalments, instalmentsTotal ]
    }

    return (
        <>
        <div className="instalments-wrapper" >
            <a className="loan-details-title-wrapper" ><strong>LOAN DETAILS</strong></a>
            <div className = "textbox-label-wrapper" >
                <div className = "label-wrapper" >
                    <LabelComponent
                        label = { instalmentComponentsList[0].label }
                    />
                </div>
                <div className = "textbox-wrapper" >
                    <InstalmentTextBoxComponent
                        textbox = { instalmentComponentsList[0].textbox }
                        value = { mortgageSum.toFixed(2) }
                    />
                </div>
            </div>
            <div className = "textbox-label-wrapper" >
                <div className = "label-wrapper" >
                    <LabelComponent
                        label = { instalmentComponentsList[2].label }
                    />
                </div>
                <div className = "textbox-wrapper" >
                    <InstalmentTextBoxComponent
                        textbox = { instalmentComponentsList[2].textbox }
                        value = { totalInstalmentsAndMonthlyInstalments()[0].toFixed(2) }
                    />
                </div>
            </div>
            <div className = "textbox-label-wrapper" >
                <div className = "label-wrapper" >
                    <LabelComponent
                        label = { instalmentComponentsList[1].label }
                    />
                </div>
                <div className = "textbox-wrapper" >
                    <InstalmentTextBoxComponent
                        textbox = { instalmentComponentsList[1].textbox }
                        value = { totalInstalmentsAndMonthlyInstalments()[1].toFixed(2) }
                    />
                </div>
            </div>
        </div>
        </>
    )
}

export default GetInstalmentsSection