import { useState } from 'react'
import GetHeader from '../../GetSections/GetHeader'
import '../../StyleFiles/signIn.css'
import MC_logo from '../../images/MC_logo.png'

const DeleteAccount = ( { onRouteChange, isSignedIn, signedInUserProfile } ) => {
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ displayedError, setDisplayedError ] = useState('')

  // *************************************************
  // *** FUNCTIONS TO SET STATE WHEN INPUT UPDATED ***
  // *************************************************
  
  // Function to update state when the email input is updated
  const onEmailChange = (event) => {
    setEmail(event.target.value)
    setDisplayedError('')
  }

  // Function to update state when the password input is updated
  const onPasswordChange = (event) => {
    setPassword(event.target.value)
    setDisplayedError('')
  }
  

  // *******************************************************
  // *** FUNCTION TO SEND DATA TO SERVER WHEN REGISTERED ***
  // *******************************************************
  
  // // Use ternary to set the url of the API when in deployment or developmemt 
  // const baseURL = process.env.NODE_ENV === "production" ? "/deleteAccount" : 'http://localhost:3001/deleteAccount'

  // Function to send registration information to server
  const onSubmitDeleteAccount = () => {
    // Restricting user from changing other's account
    if (email !== signedInUserProfile.email) {
      setDisplayedError('Only the currently signed in user account can be changed')
     } else{
      fetch('api/v1/deleteAccount', {
      method: 'post',
      headers: {'Content-Type' : 'application/json'},
      body: JSON.stringify({
        email: email, 
        password: password
     })
      })
      //  Redirection/route on received response;
      .then(res => res.json())
      .then(data => {
          console.log(data)
        if (data.includes('successfully deleted') ) {
          onRouteChange('signin')
        } else if (typeof(data) === 'string' ) {
          setDisplayedError(data)
          onRouteChange('deleteAccount')
        }
      })
     }
  };

  // ***********************************
  // *** RETURNS DELETE ACCOUNT FORM ***
  // ***********************************

  return(
    <>
      <GetHeader isSignedIn = { isSignedIn } onRouteChange = { onRouteChange } route = { 'deleteAccount' } /> 
      <p className = 'p_padding'></p>
      <article className="br2 ba dark-gray b--black-10 mv4 w-50-m w-25-l mw5 center">
        <main className="pa4 black-80 background-colour-box">
        <form className="measure center">
          <img className="logo-image" src={MC_logo} alt="oops" />
          <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
            <div className="mt3">
              <label className="db fw6 lh-copy f6" htmlFor="email-address">Email</label>
              <input 
              onChange = { onEmailChange }
              className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
              type="email" 
              name="email-address"  
              id="email-address" />
            </div>
            <div className="mv3">
              <label className="db fw6 lh-copy f6" htmlFor="password">Password</label>
              <input 
              onChange = { onPasswordChange }
              className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
              type="password" 
              name="password"  
              id="registerPassword" />
            </div>
          </fieldset>
          <div className = 'register_button_position' >
            <div className="lh-copy mt3 register_button_width">
              {/* Add event handler on click, so when clicked, the route is changed */}
              {/* Adding it as an arrow function, so it does not get called when rendered, but
              // only when clicked on. */}
              <p 
              onClick = { onSubmitDeleteAccount } 
              className="b pv2 ba grow pointer f6" 
              type="submit">Delete Account</p>
            </div>
          </div>
          <a 
            className = 'db displayed-error-text-colour fw6 lh-copy f6' 
              >{ displayedError }</a>
        </form>
      </main>
      </article>
    </>
  );
}

export default DeleteAccount;