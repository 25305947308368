import { useState } from 'react';
import AboutPopup from '../Popups/AboutPopup';
import HelpPopup from '../Popups/HelpPopup';
import '../StyleFiles/Header.css'
import MC_logo from '../images/MC_logo.png'

const GetHeader = ( { onRouteChange, isSignedIn, route } ) => {
    // To manage 'About' button state
    const [ aboutButtonState, setAboutButtonState ] = useState(false)
    const [ helpButtonState, setHelpButtonState ] = useState(false)

    if (isSignedIn) {
    return (
        <>
            <div className = "header-section">
                <AboutPopup aboutButtonState= {aboutButtonState} setAboutButtonState = {setAboutButtonState} />
                <HelpPopup helpButtonState= {helpButtonState} setHelpButtonState = {setHelpButtonState} />
                <div className = "about-background">
                    <button onClick = { () => setAboutButtonState(true) } className="header-wrapper about-account-login-register-progress-help-buttons btn-secondary"><strong>About</strong></button>
                </div>    
                    <div className="dropdown push" data-bs-theme="dark">
                        {route === 'home' 
                            ? <>
                                <button onClick = { () => setHelpButtonState(true) } className='push about-account-login-register-progress-help-buttons help-button'><img className="logo-image-header" src={MC_logo} alt="oops" /></button>
                                <button className="header-wrapper about-account-login-register-progress-help-buttons btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                                    Account
                                </button>
                            </>
                            : <>
                                <button className="header-wrapper about-account-login-register-progress-help-buttons btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                                    Account
                                </button>
                            </>
                        }
                        <ul className="dropdown-menu">
                            {route === 'home'
                                ? <>
                                    <button onClick = { () => onRouteChange('resetPassword') } href="#" className="header-wrapper account-home button dropdown-item"><strong>Reset Password</strong></button>
                                    <button onClick = { () => onRouteChange('signin') } href="#" className="header-wrapper account-home button dropdown-item"><strong>Sign Out</strong></button>
                                    <button onClick = { () => onRouteChange('deleteAccount') } href="#" className="header-wrapper account-home button dropdown-item"><strong>Delete Account</strong></button>
                                </>
                                : route === 'deleteAccount' 
                                    ? <>
                                        <button onClick = { () => onRouteChange('home') } href="#" className="header-wrapper account-home button dropdown-item"><strong>Home</strong></button>
                                        <button onClick = { () => onRouteChange('resetPassword') } href="#" className="header-wrapper account-home button dropdown-item"><strong>Reset Password</strong></button>
                                        <button onClick = { () => onRouteChange('signin') } href="#" className="header-wrapper account-home button dropdown-item"><strong>Sign Out</strong></button>
                                        
                                    </>
                                : route === 'resetPassword' 
                                    ? <>
                                        <button onClick = { () => onRouteChange('home') } href="#" className="header-wrapper account-home button dropdown-item"><strong>Home</strong></button>
                                        <button onClick = { () => onRouteChange('signin') } href="#" className="header-wrapper account-home button dropdown-item"><strong>Sign Out</strong></button>
                                        <button onClick = { () => onRouteChange('deleteAccount') } href="#" className="header-wrapper account-home button dropdown-item"><strong>Delete Account</strong></button>
                                        
                                    </>
                                : console.log('The route is not as expected')
                            }
                        </ul>
                    </div>
            </div>
        </>
    );
    } else {
        return (
            <div className = "header-section-signed-out">
                <div className = "login-register push">
                    {/* Add event handler on click, so when clicked, the route is changed */}
                    {/* Adding it as an arrow function, so it does not get called when rendered, but
                    only when clicked on. */}
                    {/* Ifelse statement to display register button when on signin page, and signin button when on register and resetPassword pages. */}
                    { route === 'register' || route === 'resetPassword'
                        ? <button 
                            onClick = { () => onRouteChange('signin') } 
                            className="header-wrapper btn-secondary about-account-login-register-progress-help-buttons btn-secondary">
                                <strong>Sign In</strong></button>
                        : route === 'signin'
                            ? <button 
                                onClick = { () => onRouteChange('register') } 
                                className="header-wrapper btn-secondary about-account-login-register-progress-help-buttons btn-secondary">
                                    <strong>Register</strong></button>
                        : console.log('something s wrong')
                }   
                </div>
            </div>
        )
    }
}

export default GetHeader