import React from "react";

const SliderComponent = ( props ) => {

    // Function component to return slider component used in Input section.
    const sliderItem =
        <> 
            <input 
                type = {props.slider.type} 
                className = {props.slider.className} 
                min = {props.slider.min} 
                max={props.slider.max} 
                step = {props.slider.step} 
                value = {props.value} 
                id = {props.slider.id} 
                onChange = {props.onChange}
                >
            </input>
        </>
    return (
        <>
            {sliderItem}
            {/* {console.log(data)} */}
        </>
    )
}

export default SliderComponent