import React from "react";

const LabelComponent = ({ label }) => {
    return (
        <> 
        <label htmlFor={label.htmlFor} className= {label.className}>{label.text}</label>
        </>
    );
}

export default LabelComponent