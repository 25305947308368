import React from "react";
import '../StyleFiles/aboutPopup.css'

const aboutPopup = (props) => {
        return (props.aboutButtonState) ? (
            <div className= "main-popup-box-container" >
                <div className="popup-box">
                    <div className="popup-header" >
                        <div><strong>Mortgage Calculator v1.0.0</strong></div>
                    </div>
                    <div className="popup-body">
                        <div className='popup-body-display'>
                            <label>MANUFACTURE DATE </label>
                            <div></div>
                            <label className='about-body-text'> 2024-02-19T21:42:58.298Z </label>
                        </div>
                        <div className='popup-body-display'>
                            <label>INTENDED USE</label>
                            <div></div>
                            <label className='about-body-text'>Mortgage Calculator is a software device intended to 
                            aid mortgage application processes. The application is intended for personal use only.</label>
                        </div>
                        <div className='popup-body-display'>
                            <label>DISCLAIMER</label>
                            <div></div>
                            <label className='about-body-text'>MortgageCalcuator is not a regulated device. The developers 
                            shall not be liable or obligated in any manner in respect of financial and/or any other damage 
                            from the use of the device.</label>
                        </div>
                        
                        <div className='popup-body-display'>
                            <label> SUPPORT </label>
                            <div></div>
                            <label className='about-body-text'>mortgage.calcul@gmail.com</label>
                        </div>
                    </div>
                    <div className="popup-footer">
                        <button className='about-close-button' onClick = { () => props.setAboutButtonState(false)}>Close</button>
                    </div>
                </div>
            </div>
        ) : "";
}

export default aboutPopup