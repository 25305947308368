import React from 'react';
import ReactDOM from 'react-dom/client';
// Functions for creating routes of the different pages
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom'

import App from './Containers/App'
import Layout from './Containers/Layout'
import ResetPasswordFromResetEmail from './Pages/ResetPasswordFromResetEmail/resetPasswordFromResetEmail';

import './StyleFiles/index.css';

import reportWebVitals from './reportWebVitals';

// Setting up routes for the start/signin/resetPassword pages; this will allow the accessing
// of the pages via the URl address (e.g. /signin)
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    <Route
      path = '/'
      element = {<Layout />}
    >
    <Route
      path = '/'
      element = {<App />}
    />
    <Route
    // Configuring the userID and resetToken to be dynamic to what ever is returned with '/:'
      path = 'resetPasswordFromResetEmail/:userId/:resetToken'
      element = {<ResetPasswordFromResetEmail />}
    />
    </Route>
    </>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // Imports the routes created; the default route is the layout (see routes; '/'), then
  // everything else is rendered inside this container (e.g. '/signin')
  <RouterProvider router = { router } />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
