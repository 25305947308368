import React from "react";

const InputTextBoxComponent = ( props ) => {

    // Function component to return textbox component used in Input section.
    const textboxItem =
            <> 
                <input 
                    type = {props.textbox.className} 
                    id = {props.textbox.id} 
                    className = {props.textbox.className} 
                    value = {props.value} 
                    step = {props.textbox.step} 
                    onChange = {props.onChange}
                    >
                </input>
            </>
            
    return (
        <>
            {textboxItem}
        </>
    )
}

export default InputTextBoxComponent