import { useState } from 'react'
import GetHeader from '../../GetSections/GetHeader'
import '../../StyleFiles/signIn.css'
import MC_logo from '../../images/MC_logo.png'

const Register = ( { onRouteChange, isSignedIn, route } ) => {
  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ confirmPassword, setConfirmPassword ] = useState('')
  const [ displayedError, setDisplayedError ] = useState('')
  // Setting up a JSON that will be saved under data in BE when user registers
  const [ data, setDat ] = useState({
    "new_mortgage":  "0", "old_mortgage": "0", "px":"0", "early_repayment":"0", 
    "stamp_duty":"0", "others":"0", "deposit":"0", "monthly_extra_payment":"0", 
    "promotional_apr":"4", "promotional_apr_term":"5", "follow_on_apr":"7", 
    "loan_term":"25"
})

  // *************************************************
  // *** FUNCTIONS TO SET STATE WHEN INPUT UPDATED ***
  // *************************************************

  // Function to update state when the name input is updated
  const onNameChange = (event) => {
    setName(event.target.value)
    setDisplayedError('')
  }
  
  // Function to update state when the email input is updated
  const onEmailChange = (event) => {
    setEmail(event.target.value)
    setDisplayedError('')
  }

  // Function to update state when the password input is updated
  const onPasswordChange = (event) => {
    setPassword(event.target.value)
    setDisplayedError('')
  }

  // Function to update state when the confirm password input is updated
  const onConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value)
    setDisplayedError('')
  }
  
  // *******************************************************
  // *** FUNCTION TO SEND DATA TO SERVER WHEN REGISTERED ***
  // *******************************************************
  
  // // Use ternary to set the url of the API when in deployment or developmemt 
  // const baseURL = process.env.NODE_ENV === "production" ? "/register" : 'http://localhost:3001/register'

  // Function to send registration information to server
  const onSubmitRegister = () => {
    fetch('api/v1/register', {
      method: 'post',
      headers: {'Content-Type' : 'application/json'},
      body: JSON.stringify({
        name: name,
        email: email, 
        password: password,
        confirmPassword: confirmPassword,
        data: data
      })
    })
    //  Redirection/route on received response;
    .then(res => res.json())
    .then(response => {
      if (response.includes('successfully registered.') ) {
        onRouteChange('signin')
      } else if (typeof(response) === 'string' ) {
        setDisplayedError(response)
        onRouteChange('register')
      }
    })
  };

  // *****************************
  // *** RETURNS REGISTER FORM ***
  // *****************************

  return(
    <>
      <GetHeader isSignedIn = { isSignedIn } onRouteChange = { onRouteChange } route = { 'register' } /> 
      <p className = 'p_padding'></p>
      <article className="br2 ba dark-gray b--black-10 mv4 center">
        <main className="pa4 black-80 background-colour-box">
        <form className="measure center">
          <img className="logo-image" src={MC_logo} alt="oops" />
          <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
            <div className="mt3">
              <label className="db fw6 lh-copy f6" htmlFor="name">Name</label>
              <input 
              onChange = { onNameChange }
              className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
              type="name" 
              name="name"  
              id="name" />
            </div>
            <div className="mt3">
              <label className="db fw6 lh-copy f6" htmlFor="email-address">Email</label>
              <input 
              onChange = { onEmailChange }
              className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
              type="email" 
              name="email-address"  
              id="email-address" />
            </div>
            <div className="mv3">
              <label className="db fw6 lh-copy f6" htmlFor="password">Password</label>
              <input 
              onChange = { onPasswordChange }
              className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
              type="password" 
              name="password"  
              id="registerPassword" />
            </div>
            <div className="mv3">
              <label className="db fw6 lh-copy f6" htmlFor="password">Confirm Password</label>
              <input 
              onChange = { onConfirmPasswordChange }
              className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
              type="password" 
              name="password"  
              id="password" />
            </div>
          </fieldset>
          <div className = 'register_button_position' >
            <div className="lh-copy mt3 register_button_width">
              {/* Add event handler on click, so when clicked, the route is changed */}
              {/* Adding it as an arrow function, so it does not get called when rendered, but
              // only when clicked on. */}
              <p 
              onClick = { onSubmitRegister } 
              className="b pv2 ba  grow pointer f6 dib " 
              type="submit">Register</p>
            </div>
          </div>
          <a 
            className = 'fw6 f6 displayed-error-text-colour' 
              >{ displayedError }</a>
        </form>
      </main>
      </article>
    </>
  );
}

export default Register;