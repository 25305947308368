import CanvasJSReact from '@canvasjs/react-charts';
import { useEffect, useRef } from "react";

const GetCharts = ( props ) => {
    const updatedValue = props.data[0]

    // *****************************************
    // **** FUNCTIONS USED FOR CALCULATIONS ****
    // *****************************************s

    // Calculates loan term values
    var numberOfPayments = function() {
        // Return term times: full, promotional, follow-on
        var numOfPaymentsFullTerm = parseFloat(updatedValue.loan_term) * 12
        var numOfPaymentsPromotionalTerm = parseFloat(updatedValue.promotional_apr_term) * 12
        var numOfPaymentsFolowOnTerm = numOfPaymentsFullTerm - numOfPaymentsPromotionalTerm
        return [numOfPaymentsFullTerm, numOfPaymentsPromotionalTerm, numOfPaymentsFolowOnTerm]
    }

    // Get mortgage sum needed
    var mortgageSum = parseFloat(updatedValue.new_mortgage -
        parseFloat((updatedValue.px - updatedValue.old_mortgage)) +
        parseFloat(updatedValue.stamp_duty) +
        parseFloat(updatedValue.early_repayment) +
        parseFloat(updatedValue.others) -
        parseFloat(updatedValue.deposit))
    
    var totalInstalmentsAndMonthlyInstalments = function(){
        // Convert the APR percentage into a float, then divide this by the number of months/year
        var promotionalAprConverted = (parseFloat(updatedValue.promotional_apr) / 100) / 12
        // Update Instalments section values
        var monthlyInstalments = ((mortgageSum*((promotionalAprConverted * (1 + promotionalAprConverted)** parseFloat(numberOfPayments()[0])) / ((1+promotionalAprConverted)**parseFloat(numberOfPayments()[0])-1))))
        var instalmentsTotal = (monthlyInstalments * numberOfPayments()[0])
        return [ monthlyInstalments, instalmentsTotal ]
    }

    // // Function to generate an array with the monthly decresing outstanding balance
    var getMonthlyOutstandingBalance = function() {
        var numOfPaymentsFullTerm = numberOfPayments()[0]
        // Convert the APR percentage into a float, then divide this by the number of months/year
        var promotionalAprConverted = (parseFloat(updatedValue.promotional_apr) / 100) / 12
        // On the other hand, the outstanding loan balance after payment m months is derived by using the below formula,
        // Outstanding Loan Balance= P * [(1 + r)**n – (1 + r)**m] / [(1 + r)**n – 1]
        // Create an array with the outstanding balances decreasing each month during the promotional period
        // Added '0' to the oth item in the array, so counting starts with '1' and these match up between the different arrays.
        var monthlyOutstandingLoanBalance = [0]
        for (var m = 1; m <= numOfPaymentsFullTerm; m++) {
            var remainingBalance = ((mortgageSum * (((1 + promotionalAprConverted) ** numOfPaymentsFullTerm) - (1 + promotionalAprConverted) ** m) / 
            (((1 + promotionalAprConverted) ** numOfPaymentsFullTerm) -1))).toFixed(2);
            if (remainingBalance >= 0) {
                monthlyOutstandingLoanBalance.push(remainingBalance)
            }
        }
        // console.log(`Outstanding Loan Balance Promotional period ${monthlyOutstandingLoanBalance}`);
        return monthlyOutstandingLoanBalance;
    }

    // Function to calculate monthly principal payments
    var getMonthlyPrincipal = function() {
        var monthlyOutstandingBalance = getMonthlyOutstandingBalance()
        var numOfPaymentsFullTerm = numberOfPayments()[0]
        var monthlyInstalments = totalInstalmentsAndMonthlyInstalments()[0]
        // Added '0' to the oth item in the array, so counting starts with '1' and these match up between the different arrays.
        var monthlyPrincipalPayments = [0]
        // Convert the APR percentage into a float, then divide this by the number of months/year
        var promotionalAprConverted = (parseFloat(updatedValue.promotional_apr) / 100) / 12
        for (var value = 1; value <= numOfPaymentsFullTerm; value++) {
            monthlyPrincipalPayments.push(monthlyInstalments - (monthlyOutstandingBalance[value] * promotionalAprConverted))
        }
        // console.log(`Monthly principal payments list : ${monthlyPrincipalPayments}`)
        return monthlyPrincipalPayments;
    }

    // Function to calculate monthly APR payments
    var getMonthlyAPR = function() {
        var monthlyPrincipalPayments = getMonthlyPrincipal()
        var numOfPaymentsFullTerm = numberOfPayments()[0]
        var monthlyInstalments = totalInstalmentsAndMonthlyInstalments()[0]

        var monthlyAprPayments = []
        for (var value = 1; value <= numOfPaymentsFullTerm; value++) {
            monthlyAprPayments.push(monthlyInstalments - monthlyPrincipalPayments[value])
        }
        // console.log(`Monthly APR payments list : ${monthlyAprPayments}`)

        return monthlyAprPayments
    }
    
    // *************************
    // **** CHART VARIABLES ****
    // *************************

    var monthlyPrincipalPayments = getMonthlyPrincipal()
    var monthlyAprPayments = getMonthlyAPR()
    var monthlyOutstandingBalance = getMonthlyOutstandingBalance()
    var monthlyInstalments = totalInstalmentsAndMonthlyInstalments()[0]
    var outstandingPrincipal120 = getMonthlyOutstandingBalance()[120]
    var outstandingPrincipal240 = getMonthlyOutstandingBalance()[240]
    
    
    // *************************
    // ******* CHART DATA ******
    // *************************

    // Add principal to the chart
    var dpsPrincipal = [];
    for (var p = 1; p <= numberOfPayments()[0]; p++) {
        dpsPrincipal.push({x:p, y: monthlyPrincipalPayments[p]})
    }
    // console.log(` dps prinsicpal: ${dpsPrincipal}`)

    // Add APR to the chart
    var dpsAPR = [];
    for (var a = 1; a <= numberOfPayments()[0]; a++) {
        dpsAPR.push({x:a, y: monthlyAprPayments[a]})
    }
    // console.log(`dps APR: ${JSON.stringify(dpsAPR)}`)

    // Add outstanding balance to the chart
    var dpsOutstandingBalance = [];
    for (var a = 1; a <= numberOfPayments()[0]; a++) {
        if (a === 1) {
            dpsOutstandingBalance.push({x:a, y: parseFloat(monthlyOutstandingBalance[a]), indexLabel: `£ ${parseFloat(monthlyOutstandingBalance[a]).toFixed(2)}`, 
                indexLabelFontColor: "pink", indexLabelFontSize: 15, indexLabelFontStyle:'bold'})
        } else {
            dpsOutstandingBalance.push({x:a, y: parseFloat(monthlyOutstandingBalance[a])})
        }
    }
    // console.log(`dps Outstanding Balance: ${JSON.stringify(dpsOutstandingBalance)}`)
    
    // Add outstanding balance to the chart
    var dpsTotalPayedAmount = [];
    for (var a = 1; a <= numberOfPayments()[0]; a++) {
        if (a < numberOfPayments()[0]) {
            dpsTotalPayedAmount.push({x:a, y: parseFloat(a * parseFloat(monthlyInstalments))})
        } else if (a === numberOfPayments()[0]) {
        dpsTotalPayedAmount.push({x:a, y: parseFloat(a * parseFloat(monthlyInstalments)), indexLabel: `£ ${parseFloat(a * parseFloat(monthlyInstalments)).toFixed(2)}`, 
            indexLabelFontColor: "yellow", indexLabelFontSize: 15, indexLabelFontStyle:'bold'})
        }
    }
    // console.log(`dps Total Payed Amount: ${JSON.stringify(dpsTotalPayedAmount)}`)
    
    // Add outstanding balance to the chart
    var dpsTotalInterestPayed = [];
    var sumOfAPR = 0
    for (var a = 1; a <= numberOfPayments()[0]; a++) {
        var monthlyAPR = parseFloat(monthlyAprPayments[a]); 
        if (a < numberOfPayments()[0]) {
            dpsTotalInterestPayed.push({x:a, y: sumOfAPR })
        } else if (a === numberOfPayments()[0]) {
            dpsTotalInterestPayed.push({x:a, y: sumOfAPR,  indexLabel: `£ ${sumOfAPR.toFixed(2)}`, 
                indexLabelFontColor: "red", indexLabelFontSize: 15, indexLabelFontStyle:'bold'})
        }
        var sumOfAPR = sumOfAPR + monthlyAPR
    }
    // console.log(`dps Total Interest Payed: ${JSON.stringify(dpsTotalInterestPayed)}`)
    
    // Add total principal payed to the chart
    var dpsTotalPrincipalPayed = [];
    var sumOfPrincipal = 0
    for (var a = 1; a <= numberOfPayments()[0]; a++) {
        var monthlyAPR = parseFloat(monthlyPrincipalPayments[a]); 
        if (a < numberOfPayments()[0]) {
            dpsTotalPrincipalPayed.push({ x:a, y: sumOfPrincipal})
        } else if (a === numberOfPayments()[0]){
            dpsTotalPrincipalPayed.push({ x:a, y: sumOfPrincipal, indexLabel: `£ ${sumOfPrincipal.toFixed(2)}`, 
                indexLabelFontColor: "orange", indexLabelFontSize: 15, indexLabelFontStyle:'bold'})
        }
        var sumOfPrincipal = sumOfPrincipal + monthlyAPR
    }
    // console.log(`dps Total Interest Payed: ${JSON.stringify(dpsTotalPrincipalPayed)}`)
    
    // *************************
    // ***** CHART OBJECT ******
    // *************************

    var CanvasJSChart = CanvasJSReact.CanvasJSChart;

    // *************************
    // ***** CHART OPTIONS *****
    // *************************

    // Amortization Chart options:
    const amortizationChartOptions = {
        animationEnabled: true,
        toolTip: {
            enabled:false
        },
        theme: "dark2", // "light1", "light2", "dark1", "dark2"
        title:{
            text: "Mortgage Amortization Chart",
            fontSize: 25,
            fontWeight: "bold"
        },
        legend: {
            fontSize: 15,
            fontColor: 'lightgrey',
        },
        axisY: {
            title: "GBP",
            titleFontSize: 20,
            titleFontColor: 'lightgrey',
            labelFontSize: 10,
            titleFontWeight: "bold",
            includeZero: true,
            gridThickness: 0.1,
            crosshair: {
                enabled: true,
                labelBackgroundColor: "gray",
                labelFontColor: "yellow",
                labelFontSize: 17,
                color:"grey",
                thickness: 2,
                snapToDataPoint: true,
                labelFormatter: function(e) {
                    return `Principal: ${e.value.toFixed(2)} GBP\n
                    APR: ${(totalInstalmentsAndMonthlyInstalments()[0] - e.value).toFixed(2)} GBP`;
                },
            },
            maximum: totalInstalmentsAndMonthlyInstalments()[0]
        },
        axisX: {
            title: "Months",
            titleFontSize: 20,
            titleFontColor: 'lightgrey',
            labelFontSize: 10,
            titleFontWeight: "bold",
            includeZero: true,
            crosshair: {
                enabled: true,
                labelBackgroundColor: "gray",
                labelFontColor: "yellow",
                labelFontSize: 17,
                color:"grey",
                thickness: 2,
                snapToDataPoint: true,
                labelFormatter: function(e) {
                    return "Months: " + e.value;
                }
              },
            stripLines: [{
                value: 120,
                showOnTop: true,
                label:  `Outstanding Principal: ${outstandingPrincipal120}`,
                labelFontSize: 15,
                labelAlign: "center",
                labelFontColor: "black"
              },
              {
                value: 240,
                showOnTop: true,
                label: `Outstanding Principal: ${outstandingPrincipal240}`,
                labelFontSize: 15,
                labelAlign: "center",
                labelFontColor: "black"
              }]
        },
        height: "450",
        data: [{        
            type: "stackedArea",
            color: 'grey',
            name: "Monthly Principal",
            showInLegend: true, 
            legendMarkerColor: "grey",
            dataPoints: dpsPrincipal
        }, 
        {        
            type: "stackedArea",
            color: '#ff8080',
            name: "Monthly APR",
            showInLegend: true, 
            legendMarkerColor: "#ff8080",
            dataPoints: dpsAPR
        }]
    }

    // Mortgage numbers Chart options:
    const mortgageNumbersChartOptions = {
        animationEnabled: true,
        toolTip: {
            enabled:false
        },
        theme: "dark2", // "light1", "light2", "dark1", "dark2"
        title:{
            text: "Mortgage Numbers",
            fontSize: 25,
            fontWeight: "bold"
        },
        subtitles: [{
            // Sets a subtitle at the top of the chart. APR changes based on currently set APR.
            text: `Based on ${updatedValue.loan_term} years term with ${updatedValue.promotional_apr}% APR`,
            fontColor: 'lightgrey',
            fontSize: 15
        }],
        legend: {
            fontSize: 15,
            dockInsidePlotArea:true,
            verticalAlign: 'top',
            horizontalAlign: 'center',
            fontColor: 'lightgrey',
            fontSize:15,
            itemWrap: false,
            EoT: 'End of Term'
        },
        axisY: {
            titleFontSize: 20,
            titleFontColor: 'lightgrey',
            labelFontSize: 0,
            titleFontWeight: "bold",
            includeZero: true,
            gridThickness: 0,
            tickLength: 0,
            crosshair: {
                enabled: false,
                labelBackgroundColor: "grey",
                labelFontColor: "yellow",
                labelFontSize: 17,
                color:"grey",
                thickness: 2,
                snapToDataPoint: false,
                labelFormatter: function(e) {
                    return `Remaining Balance: ${e.value} GBP`;
                },
            },
            maximum: totalInstalmentsAndMonthlyInstalments()[1] + (totalInstalmentsAndMonthlyInstalments()[1] * 10 / 100)
        },
        axisY2: {
            titleFontSize: 20,
            titleFontColor: 'lightgrey',
            labelFontSize: 0,
            titleFontWeight: "bold",
            includeZero: true,
            tickThickness: 0,
            gridThickness: 0,
            minimum: 0,
            maximum: totalInstalmentsAndMonthlyInstalments()[1] + (totalInstalmentsAndMonthlyInstalments()[1] * 10 / 100)
        },
        axisX: {
            title: "Months",
            titleFontSize: 20,
            titleFontColor: 'lightgrey',
            labelFontSize: 10,
            titleFontWeight: "bold",
            includeZero: true,
            crosshair: {
                enabled: false,
                labelBackgroundColor: "gray",
                labelFontColor: "yellow",
                labelFontSize: 17,
                color:"grey",
                thickness: 2,
                snapToDataPoint: false,
                labelFormatter: function(e) {
                    return "Months: " + e.value;
                }
            },
            maximum: numberOfPayments()[0] + (numberOfPayments()[0] * 5/100)
        },
        height: "450",
        data: [{        
            type: "area",
            color: '#dfff80',
            name: "Remaining Balance",
            showInLegend: true, 
            legendMarkerColor: "#dfff80",
            dataPoints: dpsOutstandingBalance,
            axisYType: 'secondary'
        },
        {        
            type: "line",
            color: 'yellow',
            name: "Total Payed Amount",
            showInLegend: true, 
            legendMarkerColor: "yellow",
            dataPoints: dpsTotalPayedAmount
        },
        {        
            type: "line",
            color: 'red',
            name: "Interest Payed",
            showInLegend: true, 
            legendMarkerColor: "red",
            dataPoints: dpsTotalInterestPayed
        },
        {
            type: "line",
            color: 'orange',
            name: "Principal Payed",
            showInLegend: true, 
            legendMarkerColor: "orange",
            dataPoints: dpsTotalPrincipalPayed
        }]
    }

    // *************************
    // ***** RENDER CHARTS *****
    // *************************

    return (
        <div className="graphs-section">
            <CanvasJSChart options = { amortizationChartOptions } />
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <CanvasJSChart options = { mortgageNumbersChartOptions } />
            {/* <CanvasJSChart options = { instalmentsChartOptions } /> */}
        </div>
        );
};

export default GetCharts