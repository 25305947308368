import { useState } from 'react';
import { useParams } from 'react-router-dom'
import MC_logo from '../../images/MC_logo.png'

import '../../StyleFiles/signIn.css'

const ResetPasswordFromResetEmail = () => {
  const [ email, setEmail ] = useState('')
  const [ newPassword, setNewPassword ] = useState('')
  const [ confirmNewPassword, setConfirmNewPassword ] = useState('')
  const [ displayedError, setDisplayedError ] = useState('')

  // Exporting reset token and the user ID from the URL to variables
  const resetToken = useParams().resetToken
  const userId = useParams().userId

  // *************************************************
  // *** FUNCTIONS TO SET STATE WHEN INPUT UPDATED ***
  // *************************************************

  // Function to update state when the email input is updated
  const onEmailChange = (event) => {
    setEmail(event.target.value)
    setDisplayedError('')
  }

  // Function to update state when the new password input is updated
  const onNewPasswordChange = (event) => {
    setNewPassword(event.target.value)
    setDisplayedError('')
  }

  // Function to update state when the confirm new password input is updated
  const onConfirmNewPasswordChange = (event) => {
    setConfirmNewPassword(event.target.value)
    setDisplayedError('')
  }

  // ******************************************************
  // *** FUNCTION TO SEND DATA TO SERVER WHEN SIGNIN IN ***
  // ******************************************************

  // // Use ternary to set the url of the API when in deployment or developmemt 
  // const baseURL = process.env.NODE_ENV === "production" ? "/resetPasswordWithToken" : 'http://localhost:3001/resetPasswordWithToken'

  // Function sends email and password request when signing in. 
  const onSubmitResetPassword = () => {
    fetch('/api/v1/resetPasswordWithToken', {
        method: 'post',
        headers: {'Content-Type' : 'application/json'},
        body: JSON.stringify({
          email: email,
          resetToken: resetToken,
          userId: userId,
          newPassword: newPassword,
          confirmNewPassword: confirmNewPassword})
      })
      //  Display message when account updated or display error message returned by the APIs
      .then(res => res.json())
      .then(data => {
        if (data.includes('Account updated successfully')) {
          setDisplayedError(data)
        } else if 
        ( typeof(data) === 'string' ) {
          setDisplayedError(data)
        }
    })
  };

  // ***********************************
  // *** RETURNS RESET PASSWORD FORM ***
  // ***********************************

  return(
    <>
      <article className="br2 ba dark-gray b--black-10 mv4 w-50-m w-25-l mw5 center">
        <main className="pa4 black-80 background-colour-box">
        <form className="measure center">
        <img className="logo-image" src={MC_logo} alt="oops" />
        <p></p>
          <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
            <div className="mt3">
              <label 
              className="db fw6 lh-copy f6" 
              htmlFor="email-address">Email</label>
              <input 
                onChange = { onEmailChange } 
                className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
                type="email" 
                name="email-address"  
                id="email-address" />
            </div>
            <div className="mv3">
              <label 
              className="db fw6 lh-copy f6" 
              htmlFor="password">New Password</label>
              <input 
                onChange = { onNewPasswordChange } 
                className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
                type="password" 
                name="password"  
                id="password" />
            </div>
            <div className="mv3">
              <label 
              className="db fw6 lh-copy f6" 
              htmlFor="password">Confirm New Password</label>
              <input 
                onChange = { onConfirmNewPasswordChange } 
                className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
                type="password" 
                name="password"  
                id="password" />
            </div>
          </fieldset>
          <div className="">
            {/* Add event handler on click, so when clicked, the route is changed */}
            {/* Adding it as an arrow function, so it does not get called when rendered, but
            // only when clicked on. */}
            <input 
              onClick = { onSubmitResetPassword } 
              className="b pv2 ba  grow pointer f6 dib reset-password-button-padding" 
              type="button" 
              value="Reset Password" />
          </div>
          <a 
            className = "fw6 displayed-error-text-colour f6" 
              >{ displayedError }</a>
        </form>
      </main>
      </article>
    </>
  );
}

export default ResetPasswordFromResetEmail;
