import React from "react";
import '../StyleFiles/helpPopup.css'

const helpPopup = (props) => {
        return (props.helpButtonState) ? (
            <div className= "main-popup-box-container" >
                <div className="popup-box">
                    <div className="popup-header" >
                        <div>MortgageCalculator v1.0.0</div>
                    </div>
                    <div className="popup-body">
                        <div className='popup-body-display'>
                            <label>1. OVERVIEW</label>
                            <div></div>
                            <label className='about-body-text'>Welcome to the Mortgage Calculator. These Instructions For Use are intended to 
                            allow you to use the product safely and efficiently. The below sections describe the key parts of the Mortgage 
                            Calculator and the intended workflow.</label>
                        </div>
                        <div className='popup-body-display'>
                            <label>2. GETTING STARTED</label>
                            <div></div>
                            <label className='about-body-text'>
	                            First, register with a Mortgage Calculator Account, login, then start planning.
                            </label>
                        </div>
                        <div className='popup-body-display'>
                            <label>2.1. USING THE MORTGAGE CALCULATOR</label>
                            <div></div>
                            <label className='about-body-text'>

                            </label>
                        </div>
                        <div className='popup-body-display'>
                            <label>2.1.1. LOGIN</label>
                            <div></div>
                            <label className='about-body-text'>
                                The Login page can be accessed from the 'http://localhost:3000/signin' web address. If you have not yet 
                                registered, you can select the ‘Register’ option to set up a Mortgage Calculator Account. A welcome email will 
                                be sent to your email address, with additional steps to follow.
                            </label>
                        </div>
                        <div className='popup-body-display'>
                            <label>2.1.2. MFA</label>
                            <div></div>
                            <label className='about-body-text'>
                                Currently we do not support the setup of Multi Factor Authentication.
                            </label>
                        </div>
                        <div className='popup-body-display'>
                            <label>2.1.3. RESET PASSWORD</label>
                            <div></div>
                            <label className='about-body-text'>
                                If you need to reset you password, you can do so from the Login page, by entering your email address, and 
                                following the instructions received in the Reset Password Request email sent to your email address. You can 
                                also reset your password once you have successfully logged in to your Mortgage Calculator Account.
                            </label>
                        </div>
                        <div className='popup-body-display'>
                            <label>2.1.4. DELETE USER ACCOUNT</label>
                            <div></div>
                            <label className='about-body-text'>
                                You can delete your account, together with all your details and saved calculations from your Mortgage Calculator 
                                Account. Just enter your email address and the matching password, and you can request the deletion of your data. 
                                Once the deletion is successful, a confirmation email will be sent to your email address.
                            </label>
                        </div>
                        <div className='popup-body-display'>
                            <label>2.1.5. USING THE CALCULATOR</label>
                            <div></div>
                            <label className='about-body-text'>
                                The Mortgage Calculator is made up of four major sections.<br></br>
                                <li><strong>Input section:</strong> here you can set the values for your calculation, either by typing 
                                in the exact numbers, or by using the provided slider elements. By setting the desired values in the 
                                “Input” section, all other sections will update dynamically.</li>
                                <li><strong>Instalments section:</strong> shows the Mortgage Sum you are set to loan, your Initial 
                                Monthly Instalments with the currently set Promotional APR, and the Total Payed Amount.</li>
                                <li><strong>Statistics section:</strong> shows a summary of your loan, together with your Monthly 
                                Instalments and APR in the Promotional and the Follow-On period.</li>
                                <li><strong>Graphs section:</strong></li>
                                <ul>
                                <li><i>Mortgage Amortization Chart:</i> follows the Monthly Principal Instalments VS Monthly APR 
                                Instalments for the length of your loan term.</li>
                                <li><i>Mortgage Numbers:</i> summarizes the Total Payed Amount, Principal Payed, and Interest Payed at 
                                the end of the loan term. An outstanding balance for each month is also displayed on the chart. <br></br>
                                <u>Note:</u> the graph shows the calculation based on the set Loan Term and the Promotional APR values.</li></ul>
                            </label>
                        </div>
                        <div className='popup-body-display'>
                            <label>2.1.4.1. SETTING THE VALUES IN THE INPUT SECTION</label>
                            <div></div>
                            <label className='about-body-text'>
                                There are 11 items to be set in this section. The promotional APR, Promotional APR Length, Follow-on APR,
                                 and Loan Term are set to default values, but these can be changed manually. Any section can be left with a 
                                 value of ‘0’, although if the Mortgage Sum is > 0, then the calculation will not be successful. Additionally, 
                                 if the part exchange amount is higher than the sum of the New Mortgage Amount and the Old Mortgage Amount, the 
                                 calculation will also fail.
                            </label>
                        </div>
                        <div className='popup-body-display'>
                            <label>2.1.5. SAVING YOUR CALCULATION</label>
                            <div></div>
                            <label className='about-body-text'>
                                Your calculation can be saved at any point of the process, by using the Progress/Reset form option in the 
                                Input section. This can be retreived on future logins. However, note that the Save option overwrites your 
                                previous calculation, and only the latest saved data will be available on your next login. Future versions 
                                will allow the saving of multiple calculations.
                            </label>
                        </div>
                        <div className='popup-body-display'>
                            <label>2.1.6. RESETTING THE FORM </label>
                            <div></div>
                            <label className='about-body-text'>           
                                The Mortgage Calculator form can be reset at any point, by using the Progress/Reset Form option from the Input 
                                section. This action will not delete your data and your latest saved calculation will be available on your next 
                                login.
                            </label>
                        </div>
                        <div className='popup-body-display'>
                            <label> 3. HELP AND SUPPORT </label>
                            <div></div>
                            <label className='about-body-text'>
                                For further help and support, please email to "mortgage.calcul@gmail.com".
                            </label>
                        </div>
                    </div>
                    <div className="popup-footer">
                        <button className='about-close-button' onClick = { () => props.setHelpButtonState(false)}>Close</button>
                    </div>
                </div>
            </div>
        ) : "";
}

export default helpPopup