import { useState } from 'react';
import '../../StyleFiles/signIn.css'
import GetHeader from '../../GetSections/GetHeader';
import MC_logo from '../../images/MC_logo.png'

const ResetPasswordFromSignIn = ( { onRouteChange } ) => {
  const [ email, setEmail ] = useState('')
  const [ displayedError, setDisplayedError ] = useState('')

  // *************************************************
  // *** FUNCTIONS TO SET STATE WHEN INPUT UPDATED ***
  // *************************************************

  // Function to update state when the email input is updated
  const onEmailChange = (event) => {
    setEmail(event.target.value)
    setDisplayedError('')
  }

  // ******************************************************
  // *** FUNCTION TO SEND DATA TO SERVER WHEN SIGNIN IN ***
  // ******************************************************

  // // Use ternary to set the url of the API when in deployment or developmemt 
  // const baseURL = process.env.NODE_ENV === "production" ? "/resetPasswordSendResetEmail" : 'http://localhost:3001/resetPasswordSendResetEmail'

  // Function sends email and password request when signing in. 
  const onSubmitSendResetEmail = () => {
    fetch('api/v1/resetPasswordSendResetEmail', {
    method: 'post',
    headers: {'Content-Type' : 'application/json'},
    body: JSON.stringify({
        email: email
    })
    })
      //  Redirection/route on received response;
      .then(res => res.json())
      .then(data => {
        if (typeof(data) === 'object') {
          onRouteChange('signin')
        } else if 
        ( typeof(data) === 'string' ) {
          setDisplayedError(data)
          onRouteChange('resetPasswordFromSignIn')
        }
    })
  }

  // ***********************************
  // *** RETURNS RESET PASSWORD FORM ***
  // ***********************************

  return(
    <>
      <GetHeader onRouteChange = { onRouteChange } route = { 'resetPassword' } /> 
      <p></p>
      <article className="br2 ba dark-gray b--black-10 mv4 w-50-m w-25-l mw5 center">
        <main className="pa4 black-80 background-colour-box">
        <form className="measure center">
          <img className="logo-image" src={MC_logo} alt="oops" />
          <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
            <div className="mt3">
              <label 
              className="db fw6 lh-copy f6" 
              htmlFor="email-address">Email</label>
              <input 
                onChange = { onEmailChange } 
                className="pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100" 
                type="email" 
                name="email-address"  
                id="email-address"/>
            </div>
          </fieldset>
          <div className="">
            {/* Add event handler on click, so when clicked, the route is changed */}
            {/* Adding it as an arrow function, so it does not get called when rendered, but
            // only when clicked on. */}
            <input 
              onClick = { onSubmitSendResetEmail } 
              className="b pv2 ba  grow pointer f6 dib reset-password-button-padding" 
              type="button" 
              value="Send Account Reset Email" />
          </div>
          <a 
            className = "fw6 displayed-error-text-colour f6" 
              >{ displayedError }</a>
        </form>
      </main>
      </article>
    </>
  );
}

export default ResetPasswordFromSignIn;