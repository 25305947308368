import React, { useState } from 'react';

import GetSections from '../GetSections/GetSections'
import GetHeader from '../GetSections/GetHeader';
import SignIn from '../Pages/signIn/signIn';
import Register from '../Pages/Register/Register';
import DeleteAccount from '../Pages/DeleteAccount/deleteAccount';
import ResetPassword from '../Pages/ResetPassword/resetPassword';
import ResetPasswordFromSignIn from '../Pages/ResetPasswordFromSignIn/resetPasswordFromSignIn';

import '../StyleFiles/index.css';

import reportWebVitals from '../reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';


export default function App ( ) {
    //  This state will track the route (page) that we are currnetly on.
    const [ route, setRoute ] = useState('signin')
    //  This state will track whether the user is currently signed in.
    const [ isSignedIn, setIsSignedIn ] = useState(false)
    // User profile returned from signin form (BE)
    const [ signedInUserProfile, setSignedInUserProfile ] = useState()
    // Function to change route on click and change the isSignedIn state based on whether the user is signed in or not
    const onRouteChange = (route) => {
        if (route === 'signin') {
            setIsSignedIn(false)
        } else if (route === 'register') {
            setIsSignedIn(false)
        } else if (route === 'home') {
            setIsSignedIn(true)
        } else if (route === 'resetPasswordFromResetEmail')
            setIsSignedIn(false)
        setRoute(route)
    }

    console.log(`userprofile ${JSON.stringify(signedInUserProfile)}`)

    // Function to update userProfile with the logged in user data (from signin form/BE)
    const setUserProfileOnSignIn = (userData) => {
        setSignedInUserProfile(userData)
    }

    return (
        <div className = 'main-div'>
            {/* IfElse statement to check the route. If on 'home', load the home page, 
            // if 'signin' only display that signin form, if 'register', display the register form. */}
            <div className='header-sections-wrapper'>
                { route === 'home'
                    ? <>    
                        <GetHeader 
                            isSignedIn = { isSignedIn } 
                            onRouteChange = { onRouteChange }
                            route = { route } 
                        />
                        <div className='input-instalment-stats-graphs-wrapper'>
                            {/* Sending the signed in user profile to the get sections component */}
                            <GetSections signedInUserProfile = { signedInUserProfile }/>
                        </div>
                    </>
                    : route === 'deleteAccount'
                        ? <DeleteAccount
                            isSignedIn = {isSignedIn}
                            onRouteChange = { onRouteChange }
                            signedInUserProfile = { signedInUserProfile }
                        />
                    : route === 'signin'
                        ? <SignIn 
                            isSignedIn = { isSignedIn } 
                            onRouteChange = { onRouteChange }
                            setUserProfileOnSignIn = { setUserProfileOnSignIn }
                        />
                    : route === 'register'
                        ? <Register 
                            isSignedIn = { isSignedIn } 
                            onRouteChange = { onRouteChange } 
                        />
                    : route === 'resetPassword'
                        ? <ResetPassword 
                            isSignedIn = { isSignedIn } 
                            onRouteChange = { onRouteChange }
                            signedInUserProfile = { signedInUserProfile }
                        />
                    : route === 'resetPasswordFromSignIn'
                        ? <ResetPasswordFromSignIn 
                            onRouteChange = { onRouteChange }
                        />
                    : console.log('An error occured') 
                }  
            </div>
        </div>
    )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
